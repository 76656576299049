import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Destaca pel seu peu llarg, de 12 cm, fibrós, coriaci, vellutat, més gruixut a la base i de color marró. El capell de 3 a 8 cm de diàmetre, passa de convexo-campanulat a aplanat, amb la cutícula vellutada de color marró castanya més o menys fosc. Les làmines són amples, espaiades, de color blanc i les espores també blanques en massa, subgloboses, de 8-10 x 6-9 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      